import React, { Component } from 'react';
import { graphql } from 'gatsby';
import marked from 'marked';

import { Container, Row, Col } from '../components/react-grid';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Pane from '../components/Pane';
import Button from '../components/Button';

import css from './community-templates-guides.module.scss';

class DownloadFile extends Component {
  render() {
    const { file } = this.props;
    if (!file) {
      return <></>;
    }

    return(
      <Row relative className={css.tg__action}>
        <Col xs="10" sm="2" className={css.tg__actionCol}>
          <Button href={file.url}>Download</Button>
        </Col>
      </Row>
    );
  }
} 

const CommunityTemplatesGuides = ({ data }) => (
  <Layout name="community-templates-guides">
    <SEO title={`${data.strapiResource.title}`} keywords={[`WAYE`, `WAYE Talks`]} />

    <Pane name="content-body" className={css.templatesGuidesGroup}>
      <Container>
        <Row>
          <Col xs="10" xs-offset="1" sm="10" sm-offset="1" className={css.templatesGuidesGroup__heading}>
            <h1>WAYE Approved Business Tools</h1>
          </Col>
        </Row>
        <Row className={css.tg}>
          <Col xs="10" xs-offset="1" sm="4" sm-offset="1" className={css.tg__title}>
            <h1>{data.strapiResource.title}</h1>
            <p>
              {data.strapiResource.excerpt}
            </p>
            <DownloadFile file={data.strapiResource.file} />
          </Col>
          <Col xs="10" xs-offset="1" sm="5" sm-offset="1" className={css.tg__body}>
            <div dangerouslySetInnerHTML={{__html: marked(data.strapiResource.body)}}></div>
          </Col>
        </Row>
      </Container>
    </Pane>
  </Layout>
);

/**
 * expose
 */

export default CommunityTemplatesGuides;

export const query = graphql`
  query CommunityTemplatesGuides($id: String) {
    strapiResource(id: {eq: $id}) {
      type
      title
      excerpt
      body
      file {
        url
      }
      updatedAt
      slug
    }
  }
`
